import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setTokan(tokan: string): void {
    localStorage.setItem('tokan', JSON.stringify(tokan));
  }

  get Tokan(): string {
    const token = localStorage.getItem('tokan');
    if (token) {
      const parseToken = JSON.parse(JSON.stringify(token));
      if (parseToken && parseToken !== 'undefined') {
        return JSON.parse(parseToken);
      }
    }
    return '';
  }

  setCurrentUser(user: any): void {
    localStorage.setItem('current-user', JSON.stringify(user));
  }

  get User(): any {
    const currentUserString = localStorage.getItem('current-user');
    if (currentUserString) {
      const parseUser = JSON.parse(JSON.stringify(currentUserString));
      if (parseUser && parseUser !== 'undefined') {
        return JSON.parse(parseUser);
      }
    }
    return '';
  }

  get CurrentUserRole(): string {
    return this.User?.role;
  }

  get CurrentUserId(): string {
    return this.User?.id;
  }
}
