import { IMember } from 'src/app/interface';
import { IUser } from './company.interface';

export class CompanyModel {
  agenciesList: [] = [];
  allemployeeList: [] = [];
  agencyemployeeList: IMember[] = [];
  ApprisalDetails: [] = [];
  revertApriasal: [] = [];
  managerEmployeeList: [] = [];
  getAgencyOffices: [] = [];
  GetAllAgencyTesting: any[] = [];
  //   userId: IUser = JSON.parse(localStorage.getItem('current-user') || '{}');
  userId: IUser;
  AgencymembersWhoDoneAppraisal: IMember[] = [];
  AllMemberWhoDoneApprisal: IMember[] = [];

  constructor() {
    try {
      const currentUserString = localStorage.getItem('current-user');
      if (currentUserString) {
        this.userId = JSON.parse(currentUserString) as IUser;
      } else {
        this.userId = {} as IUser; // Provide a default IUser object if localStorage value is null/undefined
      }
    } catch (error) {
      this.userId = {} as IUser; // Provide a default IUser object in case of parsing error
    }
  }
}
