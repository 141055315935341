import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, map, of, throwError } from 'rxjs';
import { APIBasicConfig } from '../api-basic-config';
import { IAddEmployee, ILaunchAppraisal } from 'src/app/interface';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends APIBasicConfig {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    super();
  }

  getAllAgencies(): Observable<any> {
    return this.http.get(`${this.base_url}agency/getAgencies`);
  }

  addEmployee(formData: IAddEmployee): Observable<any> {
    return this.http.post(`${this.base_url}users/register`, formData);
  }

  deleteEmployeeFromAgency(agencyId: string, id: number[]): Observable<any> {
    return this.http.delete(
      `${this.base_url}agency/${agencyId}/users?ids=${id.toString()}`
    );
  }

  editEmployee(formData: IAddEmployee): Observable<any> {
    return this.http.put(`${this.base_url}users/${formData?.id}`, formData);
  }

  getAllEmployee(): Observable<any> {
    return this.http.get(`${this.base_url}users`);
  }

  getAllEmployeeWhoDoneApprisal(data: any): Observable<any> {
    return this.http.get(
      `${this.base_url}users?page=${data?.pageSize}&limit=10&isCompleted=true`
    );
  }

  agencyEmployee(agencyId: any): Observable<any> {
    return this.http.get(
      `${this.base_url}agency/getAgencyEmployees/${agencyId?.id}`
    );
  }

  launchAppriasal(formData: ILaunchAppraisal): Observable<any> {
    return this.http.post(`${this.base_url}users/launchAppraisal`, formData);
  }
  launchSelfApriasal(formData: any): Observable<any> {
    return this.http.post(
      `${this.base_url}users/selfAnnualAppraisal`,
      formData
    );
  }

  getSelfApriasal(userId: number): Observable<any> {
    return this.http.get(
      `${this.base_url}users/getUserAppraisalDetail/${userId}`
    );
  }

  launchCompetencyApriasal(formData: any): Observable<any> {
    return this.http.post(`${this.base_url}users/annualAppraisal`, formData);
  }

  GetCompetencyApriasal(userId: number): Observable<any> {
    return this.http.get(
      `${this.base_url}users/getUserAnnualAppraisalDetail/${userId}`
    );
  }

  launchNextYearApriasal(formData: any): Observable<any> {
    return this.http.post(
      `${this.base_url}users/addUserNextYearObjective`,
      formData
    );
  }

  revertApriasal(formData: any): Observable<any> {
    return this.http.post(
      `${this.base_url}users/revertApriasal`,
      formData
    );
  }
  

  GetNextYearApriasal(userId: number): Observable<any> {
    return this.http.get(
      `${this.base_url}users/getUserNextYearDetail/${userId}`
    );
  }

  GetUserManager(userId: number,managerId: string): Observable<any> {
    return this.http.get(
      `${this.base_url}users/getUserManager/${userId}/${managerId}`
    );
  }

  getMembers(data: any): Observable<any> {
    return this.http.get(
      `${this.base_url}users/getEmployeesAgainstManager/${data?.agencyId}/${data?.managerId}/${data?.status}`
    );
  }

  assignMembers(data: any): Observable<any> {
    return this.http.post(
      `${this.base_url}users/employeesAssignstoManager`,
      data
    );
  }

  generatePDF(userId: number): Observable<any> {
    return this.http.get(`${this.base_url}users/getReport/${userId}/self_appraisal`);
  }

  downloadPDF(userId: number) {
    window.open(`${this.base_url}users/getReportFile/${userId}/self_appraisal`, '_blank');
  }

  // getAllMembersWhoDoneAppraisal(agencyId: number): Observable<any> {
  //   if(agencyId && typeof agencyId !== 'object'){
  //   var response = this.http.get(
  //     `${this.base_url}users/getAgencyCompleteAppraisalScore/${agencyId}`
  //   );
  
    
  //   return response;
  //   }else{
  //     return [] as any; 
  //   }
    
  // }

  getAllMembersWhoDoneAppraisal(agencyId: number): Observable<any> {
    if (agencyId && typeof agencyId !== 'object') {
      return this.http.get<any>(
        `${this.base_url}users/getAgencyCompleteAppraisalScore/${agencyId}`
      ).pipe(
        // Optionally handle the response if needed
        // map(response => response),  // Apply transformation if required
  
        // Handle errors
        catchError(error => {
          console.error('Error occurred while fetching members:', error);
          return throwError(error);  // Propagate the error or return an empty observable
        })
      );
    } else {
      // Return an observable with an empty array or an appropriate default value
      return of([] as any);
    }
  }

   GetAllOfficeMemberWhoDoneApprisal(data: any): Observable<any> {
    console.log("SADSADSADSAD", data,"sadasdsad>>>",data.data,"office>>>>>>>>>", data.office)
    let id = data.data
    let office = data.office
    console.log("SADSADSADSADSADCCCCCCCCCCCCCCCCCC",`${id}${office}`)
    return this.http.get(
      `${this.base_url}users/getAgencyOfficeCompleteAppraisal/${id}/${office}`
    );
    
  }


  getAgencyOffices(agencyId: number): Observable<any> {
  
    return this.http.get(
      `${this.base_url}users/getAgencyOffices/${agencyId}`
    );
    
  }

  changeMemberCaliberatedStatus(data: any): Observable<any> {
    return this.http.put(`${this.base_url}users/updateUserGrade`, data);
  }
}
